@mixin singleFixedSize($size) {
  width: $size !important;
  min-width: $size !important;
  max-width: $size !important;
  height: $size !important;
  min-height: $size !important;
  max-height: $size !important;
}

@mixin fixedSize($width, $height) {
  width: $width !important;
  min-width: $width !important;
  max-width: $width !important;
  height: $height !important;
  min-height: $height !important;
  max-height: $height !important;
}
