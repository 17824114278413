$breakpoint-2xs-width: 320px;
$breakpoint-xs-width: 428px;
$breakpoint-s-width: 744px;
$breakpoint-m-width: 1024px;
$breakpoint-l-width: 1440px;
$breakpoint-xl-width: 1920px;

@mixin max-320-break {
  @media screen and (max-width: #{$breakpoint-2xs-width}) {
    @content;
  }
}
@mixin max-428-break {
  @media screen and (max-width: #{$breakpoint-xs-width}) {
    @content;
  }
}

@mixin max-744-break {
  @media screen and (max-width: #{$breakpoint-s-width}) {
    @content;
  }
}

@mixin max-1024-break {
  @media screen and (max-width: #{$breakpoint-m-width}) {
    @content;
  }
}

@mixin max-1440-break {
  @media screen and  (max-width: #{$breakpoint-l-width}) {
    @content;
  }
}

@mixin max-1920-break {
  @media screen and  (max-width: #{$breakpoint-xl-width}) {
    @content;
  }
}

@mixin min-320-break {
  @media screen and (min-width: #{$breakpoint-2xs-width}) {
    @content;
  }
}
@mixin min-428-break {
  @media screen and (min-width: #{$breakpoint-xs-width}) {
    @content;
  }
}

@mixin min-744-break {
  @media screen and  (min-width: #{$breakpoint-s-width}) {
    @content;
  }
}

@mixin min-1024-break {
  @media screen and  (min-width: #{$breakpoint-m-width}) {
    @content;
  }
}

@mixin min-1440-break {
  @media screen and  (min-width: #{$breakpoint-l-width}) {
    @content;
  }
}

@mixin min-1920-break {
  @media screen and  (min-width: #{$breakpoint-xl-width}) {
    @content;
  }
}
