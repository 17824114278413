@mixin typography-xs {
  font-size: var(--font-size-x-small);
  line-height: var(--line-height-x-small);
}

@mixin typography-s {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}

@mixin typography-base {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

@mixin typography-m {
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);
}

@mixin typography-l {
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
}

@mixin typography-xl {
  font-size: var(--font-size-x-large);
  line-height: var(--line-height-x-large);
}

@mixin typography-2xl {
  font-size: var(--font-size-2x-large);
  line-height: var(--line-height-2x-large);
}

@mixin typography-3xl {
  font-size: var(--font-size-3x-large);
  line-height: var(--line-height-3x-large);
}

@mixin typography-4xl {
  font-size: var(--font-size-4x-large);
  line-height: var(--line-height-4x-large);
}

@mixin typography-5xl {
  font-size: var(--font-size-5x-large);
  line-height: var(--line-height-5x-large);
}

@mixin weight-regular {
  font-weight: var(--font-weight-regular);
}

@mixin weight-medium {
  font-weight: var(--font-weight-medium);
}

@mixin weight-semi-bold {
  font-weight: var(--font-weight-semi-bold);
}

@mixin weight-bold {
  font-weight: var(--font-weight-bold);
}
