@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;

.container {
  width: 100%;
  position: relative;
  height: 38px;
  border-radius: var(--cornerradius8);
  padding: 10px;
  @include min-428-break {
    height: 46px;
    padding: 12.5px 12px;
  }

  @include min-1024-break {
    height: 44px;
    padding: 11px 12px;
    border-radius: var(--cornerradius10);
    background-color: #f6f6f6;
    box-shadow: inset 0 0 0 2px #f6f6f6;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  svg {
    transition: all 0.3s ease;
    @include singleFixedSize(16px);

    @include min-428-break {
      @include singleFixedSize(20px);
    }

    @include min-1024-break {
      @include singleFixedSize(22px);
    }
  }
  &:hover {
    p {
      transition: all 0.3s ease;
      color: #303030;
    }
    span[id='copy-icon'] {
      svg {
        color: #8f8f8f;
      }
    }
  }
}

.left-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.text {
  color: #606060;
  @include typography-s;
  @include weight-medium;

  @include min-744-break {
    @include typography-base;
  }

  @include min-1024-break {
    @include typography-s;
  }

  @include min-1440-break {
    @include typography-base;
  }
}

.tooltip {
  position: absolute;
  z-index: 5;
  right: -90px;
  height: 40px;
  &_body {
    position: relative;
    border-radius: var(--cornerradius8);
    box-shadow: var(--elevation-1);
    background-color: #f6f6f6;
    padding: 10px 16px;
  }
  &_arrow {
    position: absolute;
    left: -13px;
  }
  &_text {
    color: #303030;
  }
}
